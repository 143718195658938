import { gql } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem("pickbazar_token");
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());

  function authenticate({ name, email }, cb) {
    // console.log(" LOGIN +++++++ mmmm", name, email);

    makeAuthenticated(true);

    localStorage.setItem("pickbazar_token", `${name}.${email}`);
    setTimeout(() => {}, 1000);
  }

  function signout(cb) {
    makeAuthenticated(false);
    localStorage.removeItem("pickbazar_token");
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
