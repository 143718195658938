// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = "/"
export const PRODUCTS = "/products"
export const CATEGORY = "/category"
export const PROJECT = "/project"
export const TAG = "/tag"
export const LOGIN = "/login"
export const LOGOUT = "/logout"
export const ORDERS = "/orders"
export const CUSTOMERS = "/customers"
export const COUPONS = "/coupons"
export const SETTINGS = "/settings"
export const STAFF_MEMBERS = "/staff-members"
export const SITE_SETTINGS = "/site-settings"
export const TESTIMONIAL = "/testimonial"
export const PRESTATION = "/prestation"
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = "$"
